<template>
  <button type="button" @click="$emit('clicked')" :class="classProp" :disabled="loadingProp">
    <span v-if="loadingProp"><i class="fas fa-circle-notch fa-spin"></i></span>

    <span v-else><i :class="iconProp"></i> {{ textProp }}</span>
  </button>
</template>

<script>
  export default {
    props: ['loadingProp', 'classProp', 'iconProp', 'textProp']
  }
</script>
