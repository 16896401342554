<template>
  <div class="card mt-5 shadow bg-light">
    <div class="text-center">
      <img src="../assets/images/bemag-full-grey-500.png" class="card-img-top img-fluid" style="max-width: 600px" alt="Bemag">
    </div>

    <div class="card-body">

      <div class="text-center">
        <h3 class="card-title">Registro</h3>
        <p class="text-muted">Todos los campos son obligatorios</p>
      </div>

      <div class="form-group">
        <label for="nombre">Nombre:</label>
        <input type="text" class="form-control" v-model="nombre">
      </div>

      <div class="form-group">
        <label for="apellidos">Apellidos:</label>
        <input type="text" class="form-control" v-model="apellidos">
      </div>

      <div class="form-group">
        <label for="usuario">Usuario:</label>
        <input type="text" class="form-control" v-model="usuario">
      </div>

      <div class="form-group">
        <label for="passwd">Contraseña:</label>
        <input type="password" class="form-control" v-model="passwd">
      </div>

      <div class="form-group">
        <label for="passwd2">Repetir contraseña:</label>
        <input type="password" class="form-control" v-model="passwd2">
      </div>

      <div class="form-group">
        <label for="correo">Correo:</label>
        <input type="email" class="form-control" v-model="correo">
      </div>

      <div class="form-group">
        <label for="codigoRegistro">Código de invitación:</label>
        <input type="text" class="form-control" v-model="codigoRegistro">
      </div>

      <div class="text-center">
        <LoadingButton @clicked="registro" :iconProp="'fas fa-paper-plane'" :loadingProp="loading" :classProp="'btn btn-success'" :textProp="'Enviar'" class="m-1"></LoadingButton>
        <router-link to="/login" class="btn m-1 btn-secondary"><i class="fas fa-arrow-left"></i> Volver</router-link>
      </div>

    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import Swal from 'sweetalert2'
  import config from '../config'
  import LoadingButton from '../components/LoadingButton.vue'

  export default {
    data() {
      return {
        loading: false,
        nombre: null,
        apellidos: null,
        usuario: null,
        passwd: null,
        passwd2: null,
        correo: null,
        codigoRegistro: null,
      }
    },
    components: {
      LoadingButton
    },
    methods: {
      registro: async function () {
        try {
          this.loading = true

          if (this.passwd !== this.passwd2) {
            this.loading = false

            return Swal.fire({
              title: 'Error',
              text: 'Las contraseñas no coinciden',
              icon: 'error',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true
            })
          }

          const res = await axios.post(config.apiUrl + '/usuarios/registro', {
            nombre: this.nombre,
            apellidos: this.apellidos,
            usuario: this.usuario,
            passwd: this.passwd,
            correo: this.correo,
            codigoRegistro: this.codigoRegistro,
          })

          await Swal.fire({
            title: res.data,
            text: 'Redireccionando...',
            icon: 'success',
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: false
          })

          this.$router.push({ name: 'Bienvenido' })
        } catch (e) {
          this.loading = false

          Swal.fire({
            title: 'Error',
            text: e.response.data,
            icon: 'error',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })
        }
      }
    },
    mounted() {
      this.$store.commit('setPublicLayout')
    }
  }
</script>
